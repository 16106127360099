.containerDefault,
.containerGridPreset {
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
        transform: scale(0.99);
        transition: all 0.3s;
    }

    &:hover .label {
        color: var(--blue);
    }
}

.containerDefault {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: var(--padding-small);
    align-items: center;
    padding: var(--padding-small);
    border: 1px solid var(--border);
    border-radius: var(--border-radius);
    min-width: 10.5rem;
    max-width: 15rem;
    width: min-content;

    .colourBox,
    .imageBox {
        width: 42px;
        height: 42px;
        border-radius: var(--border-radius);
        overflow: hidden;
    }
    .imageBox {
        position: relative;
        img {
            object-fit: cover;
            object-position: center;
        }
        border-radius: var(--border-radius);
    }
}

.containerGridPreset {
    width: 100%;

    .colourBox,
    .imageBox {
        aspect-ratio: 16 / 9;
        width: 100%;
        border-radius: var(--border-radius);
        box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 10%);
        overflow: hidden;
    }

    .imageBox {
        position: relative;
        img {
            object-fit: cover;
            object-position: center;
            border-radius: var(--border-radius);
        }
    }

    .label {
        display: block;
        margin-block-start: var(--margin);
    }
}

.label {
    margin: 0;
    font-weight: var(--font-weight-bolder);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 2;
    box-orientation: vertical;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
}
