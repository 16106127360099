.container {
    display: grid;
    grid-template-columns: [gutter] 1.5em [list] 1fr [gutter] 1.5em;
    grid-template-rows: 1fr auto;
    grid-template-areas: 'previous-button list next-button';
    align-items: center;
    max-width: 100%;
    &.noScroll {
        grid-template-areas: 'list list list';
    }
}

.list {
    grid-area: list;
    padding: 0;
    margin: 0;
    display: grid;
    grid-auto-columns: 1fr;
    list-style: none;
    overflow-x: auto;
    overscroll-behavior-x: contain;
    scroll-snap-type: x mandatory;
    column-gap: var(--padding);
    max-width: 100%;
    li {
        grid-row: 1;
        scroll-snap-align: start;
        min-width: max-content;
    }
}

.previousButton,
.nextButton {
    width: 48px;
    height: 48px;
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid var(--text);
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    &:hover {
        background: var(--brand-color);
        color: var(--on-brand-color);
        fill: var(--on-brand-color);
        border-color: white;
    }
    &:disabled {
        background: rgba(255, 255, 255, 0.8);
        color: var(--text-muted);
        fill: var(--text-muted);
        border-color: var(--text-muted);
        cursor: not-allowed;
    }
}

.previousButton {
    grid-area: previous-button;
    justify-self: start;
}

.nextButton {
    grid-area: next-button;
    justify-self: end;
}
